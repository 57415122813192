.signup-nav {
  height: 80px;
  position: relative;
  padding: 20px 40px 0 40px;
  background-color: var(--white);
}
.signup-logo {
  position: absolute;
  border-radius: 50%;
  width: 80px;
  top: 45px;
  left: 30px;
  cursor: pointer;
}
.signup-nav-content {
  display: flex;
  justify-content: end;
  margin-left: 150px;
}
.signup-nav-menu {
  display: none;
}
.signup-nav-menu div {
  display: flex;
}
.signup-nav-menu p {
  padding: 0 15px;
  margin-bottom: 0 !important;
  font-family: "Sans_Bold";
  cursor: pointer;
  color: var(--black);
}
/* .signup-nav-menu p:first-child,
.signup-nav-menu p:nth-child(2)  */
#contact-signup,
#about-signup
{
  font-size: 14px;
  border-right: 2px solid var(--black);
}
#contact-us {
  font-size: 12px;
  margin-top: -5px;
}

.signup-nav-account {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.signup-nav-account p {
  margin-bottom: 0 !important;
  font-family: "Sans_Bold";
  color: var(--black);
}

.sign-up-dropdown p {
  margin-bottom: 0 !important;
}

.sign-up-dropdown span {
  position: relative;
  padding-bottom: 5px;
  font-size: 12px;
  font-family: Sans_Bold;
}
.sign-up-dropdown span::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 125px;
  height: 2px;
  border-bottom: 2px solid var(--light-gray);
  content: "";
}
.person-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-peach);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.person-icon {
  color: white;
  width: 20px;
  height: 20px;
}

.dropdown-menu {
  --bs-dropdown-link-active-color: var(--black) !important;
  --bs-dropdown-link-active-bg: var(--peach) !important;
  --bs-dropdown-color: var(--black) !important;
  --bs-dropdown-link-hover-color: var(--black) !important;
  --bs-dropdown-link-hover-bg: none !important;
}

@media only screen and (min-width: 769px) {
  .signup-logo {
    top: 45px;
    left: 70px;
  }
  .signup-nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 60px;
  }
  .signup-nav-content {
    justify-content: space-between;
  }
  .signup-nav-account {
    margin-top: 0;
  }
}
@media only screen and (min-width: 992px) {
  .signup-nav-account {
    margin-right: 80px;
  }
}
