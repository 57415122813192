.manager-profile-page {
    margin: 100px auto;
    /* background-color: var(--light-gray); */
    /* min-height: 100vh; */
}
.profile-avatar-wrapper {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    cursor: pointer;
}
.manager-detail-avatar {
    height: 120px;
    width: 120px;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--gray);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.manager-detail-avatar img {
    height: 120px;
    width: 120px;
    object-fit: cover;
}    
.profile-edit-icon {
    position: absolute;
    right: 7px;
    bottom: 7px;
    position: absolute;
    color: var(--peach);
    height: 25px;
    width: 25px;
}
.modal-edit-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    position: absolute;
    color: var(--peach);
    height: 20px;
    width: 20px;
}
.manager-heading {
    font-family: 'Sans_Semi_Bold';
}
.white-box {
    flex: 1;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
}
.follow-btn {
    min-width: 140px;
    margin-top: 15px;
    padding: 3px 20px;
    background-color: var(--peach);
    border: none;
    color: var(--white);
    border-radius: 50px;
    font-family: "Sans_Semi_Bold";
    box-shadow: 0 3px 6px var(--gray);
}


@media only screen and (min-width: 1024px){
    .manager-profile-page {
        max-width: calc(65vw - 60px) !important;
    }
}