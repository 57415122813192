:root {
  --peach: #eb9481;
  /* --peach-bg: rgb(240,191,182) */
  --light-peach: rgb(249, 198, 188);
  --very-light-peach: rgb(254, 239, 234);
  --black: #000000;
  --black-russian: #000000aa;
  --white: #ffffff;
  --gray: #a5a3a3;
  --gray44: #707070;
  --light-gray: #c6c6c6;
  --dark-gray: #a5a1a1;
  --shuttle-gray: #5c626a;
  --dark-grayish: #a29a9a;
  --blue: #037ca0;
  --red: #ff0000;
  --green: #76b52c;
  --orange: #f5a31f;
  --bright-gray: #efedf4;
  --very-dark-gray: #4c4a4a;
}

/* Font faces */

/* Font faces Museo Moder */

@font-face {
  font-family: "MM_Black";
  src: url("./assets/fonts/MuseoModerno-Black.ttf");
}

@font-face {
  font-family: "MM_Bold";
  src: url("./assets/fonts/MuseoModerno-Bold.ttf");
}

@font-face {
  font-family: "MM_Extra_Bold";
  src: url("./assets/fonts/MuseoModerno-ExtraBold.ttf");
}

@font-face {
  font-family: "MM_Regular";
  src: url("./assets/fonts/MuseoModerno-Regular.ttf");
}

@font-face {
  font-family: "MM_Light";
  src: url("./assets/fonts/MuseoModerno-Light.ttf");
}

@font-face {
  font-family: "MM_Medium";
  src: url("./assets/fonts/MuseoModerno-SemiBold.ttf");
}

@font-face {
  font-family: "MM_Semi_Bold";
  src: url("./assets/fonts/MuseoModerno-SemiBold.ttf");
}

/* Font faces Sans */

@font-face {
  font-family: "Sans_Bold";
  src: url("./assets/fonts/Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "Sans_ExtraBold";
  src: url("./assets/fonts/Sans/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "Sans_Light";
  src: url("./assets/fonts/Sans/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Sans_Medium";
  src: url("./assets/fonts/Sans/OpenSans-Medium.ttf");
}

@font-face {
  font-family: "Sans_Regular";
  src: url("./assets/fonts/Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Sans_Semi_Bold";
  src: url("./assets/fonts/Sans/OpenSans-SemiBold.ttf");
}

/* Font faces Monster rat */

@font-face {
  font-family: "Monster_Black";
  src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Monster_Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Monster_Light";
  src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Monster_Medium";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Monster_Regular";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Monster_Semi_Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Monster_Thin";
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf");
}

* {
  font-family: Sans_Regular;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin-bottom: 0;
  word-wrap: break-word !important;
}

.link,
.link:focus,
.link:visited,
.link:hover,
.link:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 9px;
}

body::-webkit-scrollbar-track {
  background: var(--light-grey);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--peach);
}
button[disabled],
button:disabled {
  background-color: var(--gray);
  color: var(--white);
}
