.fav-container {
  margin: 20px;
}
.fav-page-heading {
  font-family: "MM_Bold";
}
.fav-event {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}
.fav-img {
  flex: 2;
  overflow: hidden;
  border-radius: 40px;
  width: 100%;
  height: 170px;
 
}
.fav-img img {
  width: 100%;
  height: 170px;
  object-fit: cover;

}
.fav-content {
  justify-content: space-between;
  padding: 20px;
  flex: 4;
  width: 100%;
}
.fav-content h5,
p {
  font-family: "MM_Regular";
  color: var(--gray44);
  margin-bottom: 8px !important;
}
.fav-red-icon,
.fav-red-icon svg,
.fav-red-icon svg path,
.fav-red-icon svg text {
  color: red !important;
  fill: red !important;
}

.ticket-btn_favourite {
  background-color: var(--peach) !important;
}

.ticket-btn_favourite:hover {
  background-color: var(--green) !important;
}
.manager-avatar {
  background-color: var(--gray) !important;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} 
.manager-avatar img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

@media only screen and (min-width: 587px) {
  .fav-container {
    max-width: 550px;
    margin: 0 auto;
  }
  .fav-event {
    flex-direction: row;
    justify-content: start;
  }
  .fav-img {
    flex: 3;
  }
}

@media only screen and (min-width: 769px) {
  .fav-container {
    max-width: 720px;
  }
}
