.ticket-page {
  margin: 150px 20px 50px 20px;
}
.ticket-box {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 20px;
}
.ticket-page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.ticket-page-head h4 {
  font-size: 24px;
  font-family: "MM_Regular";
  color: var(--gray44);
}
.ticket-page-head p {
  font-size: 20px;
  font-family: "MM_Regular";
  color: var(--gray44);
}

.ticket-img img {
  width: 100%;
  border-radius: 20px;
}
.ticket-content {
  padding: 20px 20px 0 20px;
}
.ticket-content label,
.ticket-price {
  font-size: 16px;
  font-family: "Monster_Semi_Bold";
}
.ticket-content input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.ticket-btn {
  margin-top: 20px;
  padding: 3px 40px;
  background-color: var(--green);
  border: none;
  color: var(--white);
  border-radius: 50px;
  font-family: "Sans_Semi_Bold";
  box-shadow: 0 3px 6px var(--gray);
}

.form-check-input {
  border: 2px solid var(--gray44) !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("../../assets/icons/icon.svg") !important;
}

.form-check-input:checked {
  background-color: var(--white) !important;
  border-color: var(--peach) !important;
}

.form-check-input:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.ticket-pkg-label {
  font-size: 14px !important;
  text-align: start;
}

@media only screen and (min-width: 587px) {
  .ticket-page {
    margin: 180px 0 80px 0;
  }
  .ticket-page-head {
    padding: 0 40px;
  }
  .event-details-container {
    max-width: 540px;
    margin: 0 auto;
  }
  .ticket-img img {
    border-radius: 40px;
  }
  .ticket-content label,
  .ticket-content p {
    font-size: 20px;
    font-family: "Monster_Semi_Bold";
  }
  .ticket-pkg-label {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 769px) {
  .event-details-container {
    max-width: 720px;
    margin: 0 auto;
  }
  .ticket-box {
    padding: 40px 100px;
  }
}

@media only screen and (min-width: 992px) {
  .event-details-container {
    max-width: 1000px;
    margin: 0 auto;
  }

  .ticket-box {
    padding: 40px 150px;
  }
}
