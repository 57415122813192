.signup-form-page {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}
.signup-form-wrapper {
  display: flex;
  align-items: center;
}
.signup-form {
  flex: 2;
  padding: 80px 40px;
  width: 100%;
  max-width: 350px;
  min-width: 350px;
}
.signup-form h2 {
  font-size: 25px;
  text-align: center;
  font-family: "MM_BOLD";
}
.signup-form-img {
  display: none;
}

.signup-form-img img {
  width: 100%;
  max-width: 800px;
}
.upload-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: var(--light-peach);
  position: relative;
  cursor: pointer;
}
.user-icon {
  color: var(--peach);
  height: 70px;
  width: 70px;
}
.edit-icon {
  position: absolute;
  color: var(--peach);
  right: -7px;
  bottom: -3px;
  height: 20px;
  width: 20px;
}
.avatar-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.checkbox-input {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.checkbox-input input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.checkbox-input label {
  color: var(--gray);
  font-family: "Sans_Medium";
  font-size: 14px;
}
.signup-btn {
  display: flex;
  justify-content: end;
}
.signup-btn button {
  margin-top: 30px;
  padding: 3px 40px;
  background-color: var(--peach);
  border: none;
  color: var(--white);
  border-radius: 50px;
  font-family: "Sans_Semi_Bold";
  box-shadow: 0 3px 6px var(--gray);
}

.form-check-input {
  border: 1px solid var(--gray44) !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("../../../assets/icons/icon.svg") !important;
}

.form-check-input:checked {
  background-color: var(--white) !important;
  border-color: var(--peach) !important;
}

.form-check-input:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.question-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-gray);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

@media only screen and (min-width: 769px) {
  .signup-form h2 {
    font-size: 30px;
  }
  .signup-form {
    margin: 80px 80px;
    padding: 0;
  }
  .signup-form-img {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
