.home-section {
  background-color: var(--light-peach);
  min-height: 100vh;
  margin-top: -60px;
  max-width: 100%;
  z-index: 2;
  position: relative;
  overflow: auto;
}
.home-bg-wrapper {
  /* background: url("../../assets/images/bg3.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 100vh; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: -2;
}
.home-container {
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  /* padding-top: 0 !important; */
  /* padding-top: 150px; */
}

.home-container-wrapper {
  min-height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.home-container-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home-container::-webkit-scrollbar {
  display: none;
}
.home-filters {
  margin-top: 150px;
}

@media only screen and (min-width: 769px) {
  .home-section {
    margin-top: -80px;
  }
  .home-container {
    max-width: 850px;
    margin: 0 auto;
    padding-top: 150px;
  }
}
