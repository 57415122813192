.home-event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}
/* .fav-event {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
} */
.home-event-img {
  flex: 3;
  position: relative;
  overflow: hidden;
}
/* .fav-img {
  flex: 2;
} */
.home-event-img img {
  border-radius: 40px;
  width: 100%;
  cursor: pointer;
  min-height: 180px;
  max-height: 180px;
  object-fit: cover;
}
.home-content {
  padding: 20px;
  flex: 4;
  width: 100%;
}

.home-event-img-actions {
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 3;
}
.home-event-info {
  flex: 5;
}
.home-event-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}
.home-event-info p {
  font-family: "MM_Regular";
  margin-bottom: 5px;
  color: var(--gray44);
  font-size: 18px;
}
.home-event-info p:first-child {
  /* font-family: "MM_Medium"; */
  font-size: 22px;
}
.see-more-detail {
  font-family: "Monster_Light" !important;
  color: var(--blue) !important;
  font-size: 14px !important;
  cursor: pointer;
}
.home-event-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}
.fav-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.calender-add-icon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.link,
.link:focus,
.link:visited,
.link:hover,
.link:active {
  text-decoration: none !important;
  color: inherit !important;
  cursor: pointer !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  cursor: pointer !important;
}

.prev-fav-icon{
  cursor: default !important;
}

@media only screen and (min-width: 587px) {
  .home-event {
    flex-direction: row;
  }
}
