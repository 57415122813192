.signup-page {
  min-height: 100vh;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .signup-page {
    /* background: url("../../../assets/images/bg3.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
  }
}


