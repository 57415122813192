.manager-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.manager-profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.manager-follow-btn {
  padding: 2px 15px;
  font-size: 14px;
  background-color: var(--peach);
  border: none;
  color: var(--white);
  border-radius: 50px;
  font-family: "Sans_Semi_Bold";
  box-shadow: 0 3px 6px var(--gray);
  cursor: pointer;
}
.manager-follow-btn[disabled],
.manager-follow-btn:disabled {
  background-color: var(--gray);
  cursor: auto;
}
.profile {
  cursor: pointer;
}
