.input-field {
  width: 100%;
  outline: none;
}

.input-field::-webkit-outer-spin-button,
  .input-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  .input-field[type="number"] {
    -moz-appearance: textfield;
  }

/* Payment Page Form Input Fields */
.payment-form-input {
  width: 100%;
  border: 1px solid var(--gray);
  outline: none;
  padding: 10px 20px;
  margin: 10px 0px;
  font-size: 15px;
}
input::-webkit-input-placeholder {
  opacity: 65%;
}
.payment-details-input {
  min-width: 100%;
  border: none;
  outline: none;
}

/* Login Page Form Input Fields */
.login-inputs {
  border: 2px solid var(--gray);
  display: block;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  margin: 10px 0;
  font-family: "Sans_Medium";
}

/* Landing page Search Input */
.landing-search-input {
  border-radius: 50px;
  padding: 7px 20px;
  border: 1px solid var(--gray44);
  width: 100%;
  outline: none;
}

/* Signup page form Inputs */
.signup-inputs {
  padding: 15px 20px;
  margin-top: 12px;
  border: 1px solid var(--gray);
}

/* HomePage Navbar search input */
.homeNav-search-input {
  position: absolute;
  top: 60px;
  left: 180px;
  border-radius: 50px;
  border: 1px solid var(--gray);
  padding: 10px 20px;
  z-index: 2;
  max-width: 300px;
}
