.payment-form {
  width: 100%;
  background-color: var(--white);
  padding: 20px;
}
.payment-form h3 {
  color: var(--gray44);
  font-size: 20px;
  text-align: center;
}
.lower-payment-form-inputs {
  display: flex;
  flex: 1;
  gap: 10px;
}
.payment-card-details {
  display: flex;
  min-width: 280px;
  max-width: 350px;
  height: 82px;
  margin: 0 auto;
  width: 100%;
  border: 1px solid var(--gray);
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 20px;
  margin-top: 10px;
}
.payment-card-details img {
  width: 80px;
  margin-right: 10px;
}

.payment-form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.payment-form-btn {
  color: var(--white);
  background-color: var(--peach);
  border: none;
  font-size: 20px;
  padding: 5px 50px;
  border-radius: 50px;
}

.payment-details-title {
  color: var(--gray);
  font-family: Sans_Bold;
  margin-bottom: 30px;
}

.active-payment {
  border: 3px solid var(--orange) !important;
}

.payment-expiry {
  color: var(--gray);
  font-size: 12px;
  margin-bottom: 0 !important;
}

.payment-details {
  text-align: center;
}
.payment-delete-icon {
  color: var(--red);
  height: 20px;
  width: 20px;
} 
.add-new-card-icon {
  height: 20px;
  width: 20px;
  color: var(--green);
}
.credit-card-img {
  width: 70px;
  /* height: 70px; */
}
.credit-card-img img {
  width: 70px;
  /* height: 70px; */
  object-fit: cover;
}
.payment-on-cash {
  height: 82px;
}
.place-order-btn[disabled],
.place-order-btn:disabled {
  background-color: var(--gray);
}


@media only screen and (min-width: 587px) {
  .payment-form {
    padding: 30px 80px;
  }
  .lower-payment-form-inputs {
    gap: 20px;
  }
  .payment-card-details {
    margin: 0;
  }
}

@media only screen and (min-width: 769px) {
  .payment-form {
    box-shadow: inset 0px 3px 6px #00000029;
    padding: 60px 40px;
  }
  .payment-form-btn-wrapper {
    justify-content: end;
    margin-top: 20px;
  }
  .payment-form-btn {
    padding: 3px 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .payment-form {
    padding: 60px 110px;
  }
}
