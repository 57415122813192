.grey-border {
    border: 1px solid var(--gray);
    border-radius: 10px;
}
.delete-account-btn {
    background-color: var(--red);
    color: var(--white);
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
}