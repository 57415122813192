.wallet-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--peach);
}
.wallet-icon {
    width: 20px;
    height: 20px;
    color: var(--white)
}
.plus-icon {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--peach);
    border-radius: 50%;
} 
.plus-icon p {
    font-size: 25px;
    color: var(--white) !important;
}
.points-bold {
    font-family: "MM_Extra_Bold";
}