.payment {
  background-color: var(--bright-gray);
  position: relative;
  padding-top: 70px;
}
.payment-igp-logo {
  border-radius: 50%;
  width: 70px;
  margin: 20px 20px;
  cursor: pointer;
}
.payment-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 40px 10px 40px;
  width: 100%;
  background-color: var(--white);
}
.payment-heading h2 {
  margin-bottom: 0;
  color: var(--peach);
  font-size: 25px;
}
.payment-haeding-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--peach);
  border-radius: 100%;
  padding: 15px;
  position: relative;
}
.payment-heading p {
  color: var(--peach);
  font-size: 20px;
  margin-bottom: 0;
  position: absolute;
  font-family: "MM_Regular";
}
.breaker-wrapper {
  padding: 30px 20px;
  background-color: var(--white);
}
.breaker {
  height: 1px;
  background-color: var(--gray44);
}
.payment-booking-wrapper {
  background-color: var(--white);
  padding-bottom: 40px;
}
.booking-details-section {
  background-color: var(--light-peach);
  padding: 20px;
  border-radius: 50px;
  margin: 0 20px;
}
.booking-details-section h4 {
  text-shadow: 0px 3px 6px var(--gray);
  color: var(--white);
  margin-left: 40px;
  font-size: 20px;
}
.booking-current-total {
  color: var(--gray44);
  display: flex;
  justify-content: space-between;
  margin: 50px 0 50px 0;
}
.booking-current-total p {
  max-width: 100px;
}
.booking-current-total p:last-child {
  margin-right: 20px;
}
.payment-success-wrapper {
  background-color: var(--white);
  padding: 20px;
}
.payment-success-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-peach);
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
}
.payment-success-section h5 {
  margin-top: 30px;
  color: var(--black-russian);
  text-align: center;
  line-height: 1.5;
}
.tickmark {
  width: 100px;
  margin-top: 10px;
}
.payment-success-booking-details {
  margin: 20px 0;
  min-width: 220px;
}
.payment-success-booking-details h6 {
  text-shadow: 0px 3px 6px var(--gray);
  color: var(--white);
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}


@media only screen and (min-width: 587px) {
  .payment {
    padding: 20px;
  }
  .payment-igp-logo {
    margin: 10 20px 0 20px;
  }
  .payment-heading-wrapper {
    padding-top: 40px;
  }
  .payment-heading {
    max-width: 300px;
  }
  .breaker-wrapper {
    padding: 0px 80px 30px 80px;
  }
  .booking-details-section {
    margin: 0px 80px;
  }
  .payment-booking-wrapper {
    padding-bottom: 100px;
  }
  .booking-current-total {
    margin-bottom: 100px;
  }
  .payment-success-booking-details {
    min-width: 280px;
  }
}

@media only screen and (min-width: 769px) {
  .payment {
    padding: 60px 20px;
  }
  .payment-igp-logo {
    position: absolute;
    width: 80px;
    top: 30px;
    left: 50px;
    cursor: pointer;
  }
  .payment-container {
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin-left: 100px !important;
    margin: 0 auto;
  }
  .payment-heading-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .payment-content-wrapper {
    display: flex;
    background-color: var(--white);
  }
  .payment-form-wrapper {
    flex: 6;
    display: flex;
    gap: 20px;
  }
  .breaker-wrapper {
    padding: 0;
    min-height: 100%;
    width: 0.5px;
    border: 0.5px solid var(--gray44);
  }
  .breaker {
    margin: 40px 0;
  }
  .payment-form-wrapper {
    padding: 50px 0 50px 50px;
  }
  .payment-booking-wrapper {
    padding: 40px 0;
  }
  .booking-details-section {
    margin: 10px 20px;
  }
  .booking-details-section h4 {
    margin-left: 10px;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .payment-container {
    margin-left: 200px !important;
    margin: 0 auto;
  }
  .booking-details-section {
    min-width: 320px;
  }
  .payment-heading {
    max-width: 450px;
  }
}
