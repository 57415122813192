@import url("https://fonts.googleapis.com/css2?family=MuseoModerno&display=swap");
.calender {
  min-height: 100vh;
  padding-bottom: 40px;
  /* background: url("../../assets/images/bg3.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
* {
  font-family: "MuseoModerno", cursive;
}

.my-calender-title{
  font-family: MM_Extra_Bold;
}

.my-calender-title-wrapper{
  display: flex;
  align-items: center;
}

.my-calender-icon{
  margin-bottom: 10px;
  margin-right: 20px;
}

.upcoming-scroll{
  overflow-y: scroll !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.upcoming-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.upcoming-scroll::-webkit-scrollbar {
  display: none;
}