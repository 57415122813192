.homeNav {
  /* display: flex;
  align-items: center;
  justify-content: end; */
  height: 60px;
  border-bottom: 1px solid var(--gray44);
  /* position: relative; */
  position: sticky;
  top: 0;
  background-color: var(--white);
  padding: 0 20px 0 0;
  z-index: 4;
}
.homeNav-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 60px;
  width: 100%;
}
.homeNav-igp-logo {
  width: 70px;
  border-radius: 50%;
  position: absolute;
  top: 4vh;
  left: 20px;
  cursor: pointer;
  z-index: 2;
}
.home-search {
  display: none;
}
.homeNav-account-section {
  display: flex;
  justify-content: end;
}
.homeNav-account {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  /* margin: 20px 40px; */
}
.homeNav-account p {
  margin-bottom: 0 !important;
  font-family: "Sans_Bold";
  color: var(--black);
}
.homeNav-person-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bright-gray);
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--black);
}

.home-dropdown p {
  margin-bottom: 0 !important;
}

.home-dropdown span {
  position: relative;
  padding-bottom: 5px;
  font-size: 12px;
  font-family: Sans_Bold;
}
.home-dropdown span::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
}
.note-dropdown {
  width: 300px;
  /* max-width: 300px; */
  height: 500px;
  cursor: auto;
  overflow-x: scroll;
}
.note-dropdown p {
  margin-bottom: 0 !important;
  word-wrap: break-word;
}

.note-dropdown span {
  position: relative;
  padding-bottom: 5px;
  font-size: 12px;
  font-family: Sans_Bold;
}
.home-dropdown span::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
}
.user-profile-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--bright-gray);
}
.user-profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.bell-icon {
  width: 24px;
  height: 24px;
  color: var(--peach);
}
.grey-clr {
  color: var(--gray44);
}
.note-item {
  word-wrap: break-word !important;
}
.note-title {
  font-size: 10px;
  color: var(--gray44) !important;
}
.note-message {
  font-family: "Sans_Medium" !important;
  font-size: 13px;
  width: 200px !important;
  min-height: 20px;
  overflow-wrap: break-word !important;
}
.dropdown-menu p {
  /* word-wrap: break-word !important; */
  word-break: break-all !important;
}
.note-subject {
  color: var(--peach) !important;
  font-size: 8px;
  text-align: end;
  margin-top: 10px;
}
.note-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--bright-gray);
}
.note-avatar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media only screen and (min-width: 769px) {
  .homeNav {
    height: 80px;
  }
  .homeNav-wrapper {
    height: 80px;
  }
  .home-search {
    display: block;
  }
  .homeNav-igp-logo {
    top: 45px;
    left: 40px;
    width: 80px;
  }
  .homeNav-account-section {
    margin-right: 80px;
  }
}
@media only screen and (min-width: 992px) {
  .homeNav-igp-logo {
    left: 60px;
  }
}
