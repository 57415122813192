.booking-deatils-card {
  background-color: var(--white);
  padding: 20px;
  border-radius: 20px;
  color: var(--gray44);
}
.ticket {
  width: 32px;
}
.booking-ticket-price {
  display: flex;
}
.booking-ticket-price p {
  font-size: 40px;
  font-weight: 700;
  /* font-family: "MM_Semi_Bold"; */
}
.symbol1 {
  font-size: 16px !important;
  margin-top: 10px;
  margin-right: 8px;
}
.symbol2 {
  font-size: 16px !important;
  margin-top: 30px;
  margin-left: 8px;
}
.booking-ticket-edit {
  display: flex;
  justify-content: space-between;
}
.booking-ticket-edit-3 {
  margin-bottom: 40px;
}
