.event-details {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 20px;
  margin: 150px 0 50px 0;
}
.event-details-head {
  display: flex;
  align-items: center !important;
  gap: 20px;
  margin-bottom: 20px;
}
.event-details-title {
  font-size: 24px !important;
  color: var(--black) !important;
  font-family: MM_Bold !important;
}
.event-details-fav {
  cursor: pointer;
}


.event-details-img {
  height: 300px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  overflow: hidden;
}
.event-details p {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: "MM_Regular";
  /* font-size: 18px; */
  color: var(--gray44);
}
.event-details-desc {
  color: var(--black) !important;
  font-size: 16px !important;
  font-family: HS_bold;
}
.event-details-btn {
  margin-top: 20px;
  padding: 3px 40px;
  background-color: var(--peach);
  border: none;
  color: var(--white);
  border-radius: 50px;
  font-family: "Sans_Semi_Bold";
  box-shadow: 0 3px 6px var(--gray);
  cursor: pointer;
}

.event-details-btn:hover {
  background-color: var(--green) !important;
}


.already-bought-ticket{
  background-color: var(--white);
  color: var(--peach) !important;
}

@media only screen and (min-width: 587px) {
  .event-details {
    padding: 40px;
    margin: 180px 0 100px 0;
  }
  .event-details-title {
    font-size: 25px !important;
  }
  .event-details-desc {
    font-size: 18px !important;
  }
  .event-details-fav {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-width: 992px) {
  .event-details-section {
    display: flex;
    gap: 40px;
  }
  .event-details-title {
    font-size: 30px !important;
  }
  .event-details-img {
    flex: 1;
  }
  .event-details-content {
    flex: 1 !important;
  }
  .event-details-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
  }
}
