.upcoming-events{
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url("../../../assets/images/bg3.png"); */
    /* background-attachment: fixed; */
    z-index: 2;
    /* overflow: auto; */
}

.upcoming-content{
    margin-left:15px;
    margin-right: 15px;
}

.upcoming-title{
    font-family: MM_Bold;
}

.upcoming-event-img{
    min-width: 160px;
    max-width: 160px;
    border-radius: 30px;
    cursor: pointer;
    min-height: 100px;
    max-height: 100px;
}

.upcoming-card-title{
    color: var(--gray44);
}

.upcoming-heart-icon{
    color: var(--red);
    fill: var(--red);
    cursor: pointer;
}

.upcoming-ticket-icon, .prev-ticket-curr{
     color: var(--dark-grayish) !important;
}

.upcoming-ticket-curr{
    font-size: 14px;
}

.upcoming-event-date{
    color: var(--blue);
    font-size: 14px;
    margin-bottom: 0;
}


.upcoming-scroll{
    
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  
  .upcoming-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .upcoming-scroll::-webkit-scrollbar {
    display: none;
  }


@media only screen and (min-width: 769px) {
    .upcoming-content{
        margin-left:150px;
        margin-right: 50px;
    }    
  }