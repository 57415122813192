.login {
  background-color: var(--peach);
  min-height: 100vh;
  max-width: 100%;
  z-index: 1;
  position: relative;
}
.login-wrapper {
  /* background: url("../../../assets/images/bg3.png"); */
  background-color: var(--light-peach);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: -2;
}
.login-header {
  background-color: var(--very-light-peach);
  height: 80px;
  position: relative;
  z-index: 2;
}
.login-igp-logo {
  width: 70px;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 20px;
  cursor: pointer;
}
.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 88vh;
  padding: 40px 0;
}
.login-content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 20px;
}
.login-content h3 {
  margin-top: 20px;
  font-family: "MM_Semi_Bold";
  margin-bottom: 0;
}
.logoImg {
  width: 100px;
  border-radius: 50%;
}
.login-form {
  width: 100%;
  margin-top: 20px;
  padding: 20px 40px;
  background-color: var(--white);
  border: 1px solid var(--gray);
  box-shadow: inset 0 3px 6px var(--bright-gray);
}
.login-forget-password p {
  margin-top: 20px;
  font-family: "Sans_light";
  font-size: 14px;
  text-align: center;
}
.login-forget-password span {
  font-family: "Sans_light";
  text-decoration: underline;
  color: var(--blue);
  cursor: pointer;
}
.login-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.login-footer p {
  padding: 0 5px;
  font-size: 12px !important;
  cursor: pointer;
  color: var(--white);
  font-family: "Sans_Semi_Bold";
}
/* .login-footer p:first-child, */
/* .login-footer p:nth-child(1) */
#contact,
#about
 {
  border-right: 3px solid var(--white);
}

.login-btn {
  display: flex;
  justify-content: center;
}
.login-btn button {
  margin-top: 15px;
  padding: 3px 40px;
  background-color: var(--peach);
  border: none;
  color: var(--white);
  border-radius: 50px;
  font-family: "Sans_Semi_Bold";
  box-shadow: 0 3px 6px var(--gray);
}
.google-login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--white);
}
.google-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.google-btn[disabled],
.google-btn:disabled {
  background-color: var(--gray);
}
.forget-send-btn {
  background-color: var(--peach) !important;
  color: var(--white) !important;
  border: 1px solid var(--peach) !important;
  padding: 6px 20px;
  border-radius: 5px;
}

@media only screen and (min-width: 420px) {
  .login-footer p{
    padding: 0 15px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) {
  .login-igp-logo {
    top: 45px;
    left: 40px;
    width: 80px;
  }
}
